import React, { useState, useEffect } from 'react';



const AdminTransferForm = ({ toggle, setModalDisplay, setNewUserToTransfer }) => {
    const [newTransferUser, setNewTransferUser] = useState({ firstName: '', lastName: '', email: '' })
    const handleTransferUserForm = (e) => {
        const { name, value } = e.target;
        setNewTransferUser({ ...newTransferUser, [name]: value })
    }
    return (
        <div>
            <div className='admin-modal-title'>ADD TEAMMATE</div>
            <div className="admin-input-container">
                <div className='admin-child-container'>
                    <input placeholder=" " name='firstName' type="text" onChange={handleTransferUserForm}></input>
                    <label>First Name</label>
                </div>
            </div>
            <div className="admin-input-container">
                <div className='admin-child-container'>
                    <input placeholder=" " name='lastName' type="text" onChange={handleTransferUserForm}></input>
                    <label>Last Name</label>
                </div>
            </div>
            <div className="admin-input-container">
                <div className='admin-child-container'>
                    <input placeholder=" " name='email' type="text" onChange={handleTransferUserForm}></input>
                    <label>Email Address</label>
                </div>
            </div>
            <div className='admin-btm-btns'>
                <button className='admin-cancel-btn' onClick={toggle}>CANCEL</button>
                <button className='admin-add-btn' onClick={() => {
                    setNewUserToTransfer(newTransferUser)
                    setModalDisplay('last-warning')
                }
                }>ADD</button>
            </div>
        </div>
    )
}


export default AdminTransferForm;