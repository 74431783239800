import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import login1 from '../../../static/images/login1.svg';
import login2 from '../../../static/images/login2.svg';
import aaiLogo from '../../../static/images/SML-Logo-WordMark.svg';
import queryString from 'query-string';
import API from '../../../lib/api-store';



const AwsLogin = ({ user, location, history, match: { params: { token } } }) => {
    const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '', company: '', awsToken: '' })
    const [isVerifying, setIsVerifying] = useState(false)
    const handleNewUserForm = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const { firstName, lastName, email, company, awsToken } = newUser
        console.log('firstName', firstName)
        const test = await API.resolveCustomer(firstName, lastName, email, company, awsToken)
        console.log('test', test)
        // history.push('/')

    }
    useEffect(() => {
        const parsed = queryString.parse(location.search);
        setNewUser({ ...newUser, awsToken: parsed.token })
    }, [])
    useEffect(() => {
        console.log('newUser', newUser)
    })
    if (user) {
        console.log('user is null', user)
        return <Redirect to="/" />; //default landing page
    }
    return (
        <div className="login">
            <form className="login-form left" onSubmit={handleSubmit}>
                <img src={aaiLogo} className="login-product-logo" alt='' />
                <div className="login-product-header">Machine Learning For Everyone</div>

                <input
                    className="login-textbox"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleNewUserForm}
                    disabled={isVerifying}
                    required
                />

                <input
                    className="login-textbox in-row"
                    name="firstName"
                    type="firstName"
                    placeholder="First Name"
                    onChange={handleNewUserForm}
                    disabled={isVerifying}
                    required
                />
                <input
                    className="login-textbox in-row"
                    name="lastName"
                    type="lastName"
                    placeholder="Last Name"
                    onChange={handleNewUserForm}
                    disabled={isVerifying}
                    required
                />

                <input
                    className="login-textbox"
                    name="company"
                    type="company"
                    placeholder="Company"
                    onChange={handleNewUserForm}
                    disabled={isVerifying}
                    required
                />
                <button
                    className="submit-button secondary-button login-button"
                    type="submit"

                >
                    {isVerifying ? 'VERIFYING...' : 'SIGN UP'}
                    <FontAwesomeIcon icon="sign-in-alt" />
                </button>


            </form>

            <div className="img-crossfade right">
                <img className="bottom" src={login1} alt="login" />
                <img className="top" src={login2} alt="login" />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.get('user') ? state.get('user').toJS() : null
});

export default withRouter(connect(mapStateToProps)(AwsLogin));

