import React, { Fragment } from 'react'


const DeleteOptions = ({ selectedUser, setModalDisplay }) => {
    return (
        <Fragment>
            {selectedUser && <div><div className='warning-title'>This user has {selectedUser.datasets.length} datasets and {selectedUser.projects.length} workspaces. Would you like to transfer these to a new or existing user?</div>
                <div className='warning-btn-container'>
                    <button onClick={() => setModalDisplay('transfer-options')}>Yes, delete user and transfer</button>
                    <button onClick={() => setModalDisplay('last-warning')}>No thanks, delete user</button>
                </div></div>}
        </Fragment>
    )
}

export default DeleteOptions