import { handleErrors } from '../errors'

export const auth = user => fetch('/auth/login', {
  method: 'POST',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  credentials: 'include',
  body: Object.keys(user).map(attr => `${attr}=${encodeURIComponent(user[attr])}`).join('&'),
}).then(handleErrors)

export const createAccount = (newUser) => fetch('/auth/create_account', {
  method: 'POST',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  credentials: 'include',
  body: Object.keys(newUser).map(attr => `${attr}=${encodeURIComponent(newUser[attr])}`).join('&'),
}).then(handleErrors)

export const sendResetEmail = email => fetch('/auth/request_reset_password', {
  method: 'POST',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  credentials: 'include',
  body: `email=${encodeURIComponent(email)}`,
}).then(handleErrors)

export const resetPassword = (pass, id) => fetch('/auth/reset_password', {
  method: 'POST',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  credentials: 'include',
  body: `password=${encodeURIComponent(pass)}&id=${id}`,
}).then(handleErrors)

export const checkResetExpiration = query => fetch(`/auth/reset_expired?id=${query}`).then(handleErrors)

export const quit = () => fetch('/auth/logout', {
  method: 'POST',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  credentials: 'include',
}).then(handleErrors)

export const fetchAdminData = () => fetch('/auth/fetch_admin_data', {
  method: 'GET',
}).then(handleErrors)
