import React, { useState, useEffect } from 'react';


const AdminTransferOptions = ({ usersData, setModalDisplay, userToTransfer, setUserToTransfer, setMethod }) => {
    const [option, setOption] = useState(null)
    return (
        <div style={{ padding: '0 1rem' }}>
            <div>Would you like to transfer user's datasets and workspaces to an </div>
            <div className='transfer-container'>
                <div>
                    <label className="pure-material-radio">
                        <input type="radio" name="group" checked={option === '1'} onChange={() => setOption('1')}></input>
                        <span>Existing Teammate</span>
                    </label>
                    <div className='admin-select-container'>
                        <div className={option === '1' ? "mdl-selectfield admin-selected" : "mdl-selectfield"}>
                            <label>Standard Select</label>
                            <select className={option === '1' ? 'admin-select admin-selected' : 'admin-select'}
                                onClick={() => {
                                    setOption('1')
                                }}
                                onChange={e => {
                                    const parsed = JSON.parse(e.currentTarget.value)
                                    setUserToTransfer({
                                        firstName: parsed.name.first,
                                        lastName: parsed.name.last,
                                        _id: parsed._id
                                    })
                                }}
                            >
                                <option value="" disabled selected>Choose your option</option>
                                {
                                    usersData && usersData.map(user => {
                                        return (
                                            <option
                                                value={JSON.stringify(user)}>
                                                {user.name.first + ' ' + user.name.last}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                <label className="pure-material-radio">
                    <input type="radio" name="group" checked={option === '2'} onChange={() => setOption('2')}></input>
                    <span>New User</span>
                </label>
                <div className='transfer-btn-container'>
                    <button
                        className={option === '1' ? Object.keys(userToTransfer) !== 0 ? 'transfer-btn' : 'transfer-btn disab' : 'transfer-btn'}
                        disabled={option === '1' ? userToTransfer._id : false}
                        onClick={
                            option === '1' ? () => { setModalDisplay('last-warning'); setMethod('transfer') } : () => { setModalDisplay('user-transfer-form'); setMethod('newUser') }
                        }
                    >CONTINUE</button>
                </div>

            </div>
        </div>
    )
}


export default AdminTransferOptions