import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../redux/actions';
import { quit } from '../../lib/api-auth/api-auth';
import aaiLogo from '../../static/images/SML-Logo-WordMark.svg';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from 'react-router-dom';
import {
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	// NavLink,
	UncontrolledDropdown,
	NavbarText
} from 'reactstrap';
import SupportDropdown from './User/SupportDropdown';
import CircleText from '../General/CircleText';

class ProductBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			supportDropdownOpen: false,
			isOpen: false,
		};
	}
	toggle = () => {
		return this.setState({ isOpen: !this.state.isOpen })
	}
	handleLogout = (e) => {
		e.preventDefault();
		const { handleLogout } = this.props;
		handleLogout();
	};

	toggleAcountDropdown = (e) => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};

	toggleSupportDropdown = (e) => {
		this.setState({ supportDropdownOpen: !this.state.supportDropdownOpen });
	};

	redirectToProjects = (e) => {
		e.stopPropagation();
		this.props.history.push(`/projects`);
	};

	redirectToDatasets = (e) => {
		e.stopPropagation();
		this.props.history.push(`/datasets`);
	};

	handleLogout = () => {
		const { logoutAction } = this.props;
		quit();
		logoutAction();
		this.props.history.push('/login');
	};


	render() {
		const { user } = this.props;
		const { dropdownOpen } = this.state;
		const myaccountDropdown = user && (
			<Dropdown
				className="nav my-account-nav"
				isOpen={dropdownOpen}
				inNavbar={true}
				toggle={this.toggleAcountDropdown}
			>
				<DropdownToggle nav>
					{
						user && user.firstName.length && user.lastName.length ? <CircleText text={(user.firstName[0] + user.lastName[0]).toUpperCase()} /> : null
					}

				</DropdownToggle>
				<DropdownMenu right>
					<DropdownItem onClick={() => this.props.history.push('/myaccount')}>My Profile</DropdownItem>
					{user &&
						user.tier_status > 4 && (
							<DropdownItem onClick={() => this.props.history.push('/qriadmin')}>QRI Admin</DropdownItem>
						)}
					{user &&
						user.admin && <DropdownItem onClick={() => this.props.history.push('/admin')}>Admin</DropdownItem>}
					<DropdownItem onClick={this.handleLogout}>Logout</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);

		const navigation = (
			<Fragment>
				<NavItem>
					<NavLink className="nav-text" exact to="/">
						Home
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className="nav-text" exact to="/datasets">
						Datasets
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className="nav-text" exact to="/projects">
						Experiments
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className="nav-text" exact to="/apimanage">
						API Keys
					</NavLink>
				</NavItem>
				<NavItem>
					<SupportDropdown />
				</NavItem>
				<NavItem>
					{myaccountDropdown}
				</NavItem>

			</Fragment>
		);

		return (
			user && (
				<div className='header-outer'>

					<Navbar color="faded" light expand='md' className='product-header'>
						<div>
							<img
								className="logo"
								src={aaiLogo}
								alt="Augmented AI Logo"
								onClick={() => {
									this.props.history.push('/');
								}}
							/>
							<div
								className="product-name"
								onClick={() => {
									this.props.history.push('/');
								}}
							/>
						</div>
						<NavbarToggler onClick={this.toggle} className="mr-2" />
						<Collapse isOpen={this.state.isOpen} navbar>
							<Nav navbar className="ml-auto">
								{navigation}
							</Nav>
						</Collapse>
					</Navbar>

				</div>
			)
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.get('user') ? state.get('user').toJS() : null
});

const mapDispatchToProps = (dispatch) => ({
	logoutAction: (user) => dispatch(logout(user))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductBar));




// return (
// 	user && (
// 		<div className="header-outer">
// 			<div className="product-header">
				// <img
				// 	className="logo"
				// 	src={aaiLogo}
				// 	alt="Augmented AI Logo"
				// 	onClick={() => {
				// 		this.props.history.push('/');
				// 	}}
				// />
				// <div
				// 	className="product-name"
				// 	onClick={() => {
				// 		this.props.history.push('/');
				// 	}}
				// />
// 				{navigation}
// 			</div>
// 		</div>
// 	)
// );