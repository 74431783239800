import React, { useState, useEffect } from 'react';
import API from '../../../lib/api-store';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';



const AdminNewUserForm = ({ toggle, setAdminModal, user, addUserToState, getUsersForAdmin }) => {
    const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '' })
    const [clicked, setClicked] = useState(false)
    const handleNewUserForm = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value })
    }

    return (
        <div>
            <div className='admin-modal-title'>ADD TEAMMATE</div>
            <div className="admin-input-container">
                <div className='admin-child-container'>
                    <input placeholder=" " name='firstName' type="text" onChange={handleNewUserForm}></input>
                    <label>First Name</label>
                </div>
            </div>
            <div className="admin-input-container">
                <div className='admin-child-container'>
                    <input placeholder=" " name='lastName' type="text" onChange={handleNewUserForm}></input>
                    <label>Last Name</label>
                </div>
            </div>
            <div className="admin-input-container">
                <div className='admin-child-container'>
                    <input placeholder=" " name='email' type="text" onChange={handleNewUserForm}></input>
                    <label>Email Address</label>
                </div>
            </div>


            {
                clicked ? <Loader type="Oval" width={40} color="#0076c0" style={{ textAlign: 'center' }} /> : <div className='admin-btm-btns'>
                    <button className='admin-cancel-btn' onClick={toggle}>CANCEL</button>
                    <button className='admin-add-btn' onClick={async () => {
                        setClicked(true)
                        const r = await API.createNewUser(newUser.email, newUser.firstName, newUser.lastName, user.company.name, null, true, false)
                        getUsersForAdmin()
                        setClicked(false)
                        setAdminModal(false)

                    }
                    }>ADD</button>
                </div>
            }

        </div>
    )
}



const mapStateToProps = (state) => ({
    user: state.get('user') ? state.get('user').toJS() : null
});
export default connect(mapStateToProps)(AdminNewUserForm);