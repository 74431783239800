import React from 'react';


const SuccessUserAdded =() => {


    return (
        <div>
            sucess user added
        
        </div>)
}


export default SuccessUserAdded;