import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import LoginForm from '../components/Layout/User/LoginForm';
import ResetPassword from '../components/Layout/User/ResetPassword';
import MyAccount from '../components/Layout/User/MyAccount';
import Membership2 from '../components/Layout/User/Membership2';
import AdminPage from '../components/Layout/Admin/AdminPage';
import AwsLogin from '../components/Layout/User/AwsLogin'

// Product Space Pages
const Case = lazy(() => import('../components/Layout/Case/Case'));
const FaqHome = lazy(() => import('../components/Layout/Faq/FaqHome'));
const FaqCategoryPage = lazy(() => import('../components/Layout/Faq/FaqCategoryPage'));
const ExperimentsPage = lazy(() => import('../components/Layout/Project/ExperimentsPage'));
const MainLandingPage = lazy(() => import('../components/Layout/User/MainLandingPage'));
const DatasetsPage = lazy(() => import('../components/Layout/Dataset/DatasetsPage'));
const ApiPage = lazy(() => import('../components/Layout/ApiPage/ApiPage'));
const DatasetPage = lazy(() => import('../components/Layout/Dataset/DatasetPage'));
const QRIAdminPage = lazy(() => import('../components/Layout/Admin/QRIAdminPage'));
const ProductSpaceRoutes = ({ user }) => (
	<React.Fragment>
		<Route exact path="/reset" component={ResetPassword} />
		<Route
			exact
			path="/login"
			render={(props) => (
				<div className="page-wrap">
					<LoginForm {...props} user={user} />
				</div>
			)}
		/>
		<Route exact path="/awslogin" user={user} component={AwsLogin} />
		<PrivateRoute exact path="/" user={user} component={MainLandingPage} />
		<PrivateRoute exact path="/myaccount" user={user} component={MyAccount} />
		<PrivateRoute exact path="/membership" user={user} component={Membership2} />
		<PrivateRoute exact path="/experiment" user={user} component={Case} />
		<PrivateRoute exact path="/datasets" user={user} component={DatasetsPage} />
		<PrivateRoute path="/dataset/:_id" user={user} component={DatasetPage} />
		<PrivateRoute exact path="/projects" user={user} component={ExperimentsPage} />
		<PrivateRoute exact path="/apimanage" user={user} component={ApiPage} />
		<PrivateRoute exact path="/faq" user={user} component={FaqHome} />
		<PrivateRoute path="/faq/:category" user={user} component={FaqCategoryPage} />
		<PrivateRoute path="/project/:_id" user={user} component={ExperimentsPage} />
		<PrivateRoute path="/qriadmin" user={user} component={QRIAdminPage} />
		<PrivateRoute path="/admin" user={user} component={AdminPage} />
	</React.Fragment>
);

export default ProductSpaceRoutes;
