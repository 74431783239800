import { fromJS } from 'immutable';

const initialStates = {
	user: null,
	logs: fromJS({
		machineLearning: {}
	}),
	globalMsg: null,
	waitPreprocessing: fromJS({ opLabel: null, flag: false }),
	cse: null,
	project: null,
	dataset: null,
	supportTopic: '',
	preprocessModal: '',
	sortOption: ''
};


const supportTopic = (state = initialStates.supportTopic, action) => {
	switch (action.type) {
		case 'CLOSE':
			return '';
		case 'OPEN':
			return action.topic;
		default:
			return state;
	}
};

const preprocessModal = (state = initialStates.preprocessModal, action) => {
	switch (action.type) {
		case 'CLOSE_PREPROCESS':
			return '';
		case 'OPEN_PREPROCESS':
			return action.payload;
		default:
			return state;
	}
}

const cse = (state = initialStates.cse, action) => {
	switch (action.type) {
		case 'ENTER_CASE':
			return fromJS(action.case);
		default:
			return state;
	}
};

const project = (state = initialStates.project, action) => {
	switch (action.type) {
		case 'ENTER_PROJECT':
			return fromJS(action.project);
		default:
			return state;
	}
};

const dataset = (state = initialStates.dataset, action) => {
	switch (action.type) {
		case 'ENTER_DATASET':
			return fromJS(action.dataset);
		case 'ENTER_PROJECT':
			return fromJS(action.dataset);
		default:
			return state;
	}
};

const logs = (state = initialStates.logs, action) => {
	switch (action.type) {
		case 'MACHINE_LEARNING_LOG_RECEIVED':
			return state.updateIn(['machineLearning', action.payload.projectId], (list) => {
				if (list) {
					return list.push(fromJS(action.payload));
				}
				return fromJS([action.payload]);
			});
		default:
			return state;
	}
};

const waitProprocessing = (state = initialStates.waitPreprocessing, action) => {
	switch (action.type) {
		case 'WAIT_PREPROCESSING':
			return fromJS({ flag: action.preprocessing.flag, opLabel: action.preprocessing.opLabel });
		default:
			return state;
	}
};

const globalMsg = (state = initialStates.globalMsg, action) => {
	switch (action.type) {
		case 'SET_GLOBAL_MSG':
			return fromJS(action.msgs);
		case 'WAIT_PREPROCESSING':
			return fromJS(action.preprocessing.resultQCMsgs);
		default:
			return state;
	}
};

const user = (state = initialStates.user, action) => {
	switch (action.type) {
		case 'LOGIN':
			return fromJS(action.user);
		case 'LOGOUT':
			return null;
		default:
			return state;
	}
};
const sortOption = (state = initialStates.sortOption, action) => {
	switch (action.type) {
		case 'CHANGE':
			return fromJS(action.payload);
		default:
			return state
	}
}


export default {
	logs,
	user,
	waitProprocessing,
	globalMsg,
	cse,
	dataset,
	project,
	supportTopic,
	preprocessModal,
	sortOption
};
