import Fuse from "fuse.js";
import Papa from "papaparse";
import isISO8601 from "validator/lib/isISO8601";
import isRFC3339 from "validator/lib/isRFC3339";
import { ACCEPTABLE_SIZE_MB } from "./errors";
import XLSX from "xlsx";

///////////////////////
// Library Variables //
///////////////////////
const ASCII_PATTERN = /^[ -~\t\n\rÀ-úÓ]+$/;

const SI_PREFIX = [
  { value: 1e12, symbol: "T" },
  { value: 1e9, symbol: "B" },
  { value: 1e6, symbol: "MM" },
  { value: 1e3, symbol: "k" },
];

const BYTE_PREFIX = [
  { value: 1e12, symbol: "T" },
  { value: 1e9, symbol: "G" },
  { value: 1e6, symbol: "M" },
  { value: 1e3, symbol: "K" },
];

/**
 * Converts integer to a formatted string representing a
 * decimal with significant figures and condensed standard unit appended.
 *
 * @param  {Number} num         Number to be formatted.
 * @param  {Number} digits      Number of digits in front of decimal point.
 * @param  {String} [unit='']   Base unit to be appended.
 * @return {String}             Formatted string.
 */
export const shortenNum = (num, digits, unit = "") => {
  if (num === undefined || typeof num !== "number") {
    return num;
  }

  if (num < 0.001) {
    return `0${unit ? `${unit}` : ""}`;
  }

  const conversion = unit ? SI_PREFIX : BYTE_PREFIX;

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  for (let i = 0; i < SI_PREFIX.length; i += 1) {
    if (num >= SI_PREFIX[i].value) {
      return `${(num / SI_PREFIX[i].value).toFixed(digits).replace(rx, "$1")} ${conversion[i].symbol
        }${unit}`;
    }
  }

  const finalNum = num.toFixed(digits);

  if (finalNum === "0") {
    return `0${unit ? `${unit}` : ""}`;
  }

  return `${num.toFixed(digits).replace(rx, "$1")}${unit ? `${unit}` : ""}`;
};

export const arrayToObject = (array, keyField, valueField) => {
  let obj = {};
  for (const item of array) {
    obj[item[keyField]] = item[valueField];
  }
  return obj;
};

export const stringToDate = (s) => new Date(Date.parse(s));

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const beautifyTimeElapsed = (milliseconds) => {
  if (milliseconds < 1000) {
    return `${Math.round(milliseconds)} ms`;
  } else if (milliseconds < 6e4) {
    return `${Math.round(milliseconds / 1000)} secs`;
  } else {
    return `${Math.round(milliseconds / 6e4)} minutes`;
  }
};

export const beautifyValue = (v) => {
  const vtype = typeof v;
  if (vtype == "number") {
    if (Number.isInteger(v)) return v;
    if (v < 10000) return Math.round(v * 1000) / 1000;
    else return v.toExponential(3);
  } else if (vtype == "boolean") {
    return v.toString();
  } else if (vtype == "string") {
  } else if (vtype == "object") {
    if (v instanceof Date && !isNaN(v.valueOf())) {
      v = v.toLocaleString();
    } else if (Array.isArray(v)) {
      return v.join(", ");
    } else if (v && v.hasOwnProperty("content")) {
      return v.content;
    }
  }
  return v;
};

export const togglableClassName = (condition, className) => {
  return condition ? className : `${className} d-none`;
};

export const spacifyCamelString = (x) => {
  return (
    x
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const getFilteredData = (
  data,
  {
    searchText = "",
    searchKeys = [],
    pageSize = null,
    pageIndex = null,
    filters = null,
    sortBy = null,
  } = {}
) => {
  if (filters) {
    data = data.filter((el) => {
      for (let key of Object.keys(filters)) {
        const val = el[key];
        const filterVal = filters[key];
        if (typeof val === "string" || val instanceof String) {
          if (!filterVal.includes(val)) {
            return false;
          }
        } else {
          if (val > filterVal[1] || val < filterVal[0]) {
            return false;
          }
        }
      }

      return true;
    });
  }

  if (searchText && searchKeys) {
    const fuse = new Fuse(data, {
      keys: searchKeys,
      shouldSort: true,
      threshold: 0.6,
    });
    data = fuse.search(searchText);
  }

  if (sortBy) {
    data.sort((a, b) => {
      for (let i = sortBy.length - 1; i >= 0; i--) {
        const sort = sortBy[i];
        const { id, desc } = sort;
        if (!a.hasOwnProperty(id)) {
          return 1;
        } else if (!b.hasOwnProperty(id)) {
          return -1;
        }
        if (a[id] !== b[id]) {
          return (a[id] < b[id]) ^ desc ? -1 : 1;
        }
      }
      return 0;
    });
  }

  let slicedData = data;
  let pageCount = 1;

  if (pageSize) {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    pageCount = Math.ceil(data.length / pageSize);
    slicedData = data.slice(startRow, endRow);
  }

  return { slicedData, pageCount };
};

export const uploadFile = (url, file, onProgressHandler) =>
  new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    // Send the proper header information along with the request
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.upload.addEventListener("progress", onProgressHandler, false);
    // Call a function when the state changes
    xhr.onreadystatechange = function onReadyStateChange() {
      if (this.readyState === XMLHttpRequest.DONE) {
        resolve(xhr);
      }
    };
    xhr.send(file);
  });

export const downloadFile = (url, name) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function onload() {
      if (this.status === 200) {
        let filename = name;
        const type = xhr.getResponseHeader("Content-Type");
        const blob =
          typeof File === "function"
            ? new File([this.response], filename, { type })
            : new Blob([this.response], { type });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const URL = window.URL || window.webkitURL;
          const downloadUrl = URL.createObjectURL(blob);
          if (!filename) {
            filename = "data.csv";
          }
          // use HTML5 a[download] attribute to specify filename
          const a = document.createElement("a");
          // safari doesn't support this yet
          if (typeof a.download === "undefined") {
            window.location = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
        resolve({ success: true });
      } else {
        const decodedString = String.fromCharCode.apply(
          null,
          new Uint8Array(this.response)
        );
        const obj = JSON.parse(decodedString);
        reject(obj);
      }
    };
    xhr.send();
  });

export const determineValType = (val) => {
  if (
    typeof val === "string" &&
    (isISO8601(val, { strict: true }) || isRFC3339(val))
  ) {
    return "date";
  }
  if (typeof val === "number") {
    return "number";
  }
  if (typeof val === "string" || typeof val === "boolean") {
    if (val === "NaN") {
      return "number";
    }
    return "string";
  }
  return typeof val;
};

// check if the signature matches
export const doesSignatureMatch = (signatureRequired, signature) => {
  for (const k of Object.keys(signatureRequired)) {
    if (
      !(signature.hasOwnProperty(k) && signature[k] === signatureRequired[k])
    ) {
      return false;
    }
  }
  return true;
};

export const parseCsv = (file, preview = null) =>
  new Promise((resolve, reject) => {
    const columnTypes = {};
    let count = 0;
    const parseErrors = [];
    const regex = /[^A-Z a-z0-9_/]/;
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      encoding: "ISO-8859-1",
      chunkSize: 1024 * 1024 * 0.25,
      beforeFirstChunk: (chunk) => {
        const rows = chunk.split(/\r\n|\r|\n/);
        const headings = rows[0].split(",");
        const duplicateHeading = headings.filter(
          (item, index) => headings.indexOf(item) != index
        );
        if (duplicateHeading.length) {
          parseErrors.push({
            code: "DUPLICATE HEADER",
            msg: `Your file has a duplicate header "${duplicateHeading[0]}"`,
          });
          resolve({ columnTypes, file, parseErrors });
        }
      },
      step: function (row, parser) {
        for (let [col, val] of Object.entries(row.data)) {
          if (
            typeof val === "string" &&
            val.length > 0 &&
            !ASCII_PATTERN.test(val)
          ) {
            parseErrors.push({
              code: "INVALID_SYMBOL",
              msg: `Please remove special characters from row ${count + 2
                }: ${val}`,
            });
            resolve({ columnTypes, file, parseErrors });
            parser.abort();
          }
        }
        if (!(preview && count >= preview)) {
          for (let [col, val] of Object.entries(row.data)) {
            if (count < 1) {
              if (col === "") {
                parseErrors.push({
                  code: "EMPTY_COLUMN_NAME",
                  msg: "Your file has a column with empty name!",
                });
                resolve({ columnTypes, file, parseErrors });
                parser.abort();
              } else if (col.startsWith(" ") || col.endsWith(" ")) {
                parseErrors.push({
                  code: "INVALID_COLUMN_NAME",
                  msg: `Column name cannot start or end with space: ${col}!`,
                });
                resolve({ columnTypes, file, parseErrors });
                parser.abort();
              }
            }
            if (typeof val !== "object") {
              if (
                !columnTypes.hasOwnProperty(col) ||
                columnTypes[col] !== "string"
              ) {
                columnTypes[col] = determineValType(val);
              }
            }
          }
          if (count < 1) {
            if (row.meta.delimiter !== ",") {
              parseErrors.push({
                code: "INVALID_DELIMITER",
                msg: "Please use commas as a delimiter",
              });
              resolve({ columnTypes, file, parseErrors });
              parser.abort();
            }
            const invalidSymbol = row.meta.fields.reduce(
              (prev, field) => (prev ? prev : regex.test(field)),
              false
            );
            if (invalidSymbol) {
              parseErrors.push({
                code: "INVALID_COLUMN_HEADER",
                msg: "Please remove special characters from column names",
              });
              resolve({ columnTypes, file, parseErrors });
              parser.abort();
            }
          }
        }
        count++;
      },
      complete: function () {
        resolve({ columnTypes, file, parseErrors });
      },
      error: function () {
        reject("err");
      },
    });
  });

export const xlsxParse = async (file, preview = null) =>
  new Promise((resolve) => {
    var reader = new FileReader();
    let columnTypes = {};
    let parseErrors = [];
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const regex = /[^A-Z a-z0-9_/]/;
      dataParse[0].forEach((label, index) => {
        columnTypes[label] = typeof dataParse[1][index];
        const invalidSymbol = regex.test(label);
        if (invalidSymbol) {
          parseErrors.push({
            code: "INVALID_COLUMN_HEADER",
            msg: "Please remove special characters from labels",
          });
        }
      });
      resolve({ columnTypes, file, parseErrors });
    };
    reader.readAsBinaryString(file);
  });

export const isFileAcceptable = async (
  acceptedExt,
  file,
  requiredSignature
) => {
  let extension = file.name.split(".");
  extension = "." + extension[extension.length - 1].toLowerCase();
  const checks = [
    {
      check: acceptedExt.includes(extension),
      code: "INCORRECT_TYPE",
      errorMsg: `File should be one of the following types ${acceptedExt.join(
        ", "
      )}`,
    },
    {
      check: file.size / 1024 / 1024 < ACCEPTABLE_SIZE_MB,
      errorMsg: `File over ${ACCEPTABLE_SIZE_MB} MB`,
      code: "FILE_SIZE_LIMIT",
    },
  ];

  let errors = [];
  //let isAcceptable = Object.values(checks).reduce((prev, { check, code, errorMsg }) => {
  let isAcceptable = Object.keys(checks)
    .map((k) => checks[k])
    .reduce((prev, { check, code, errorMsg }) => {
      if (!check) {
        errors.push({ code, msg: errorMsg });
      }
      return prev && check;
    }, true);
  if (isAcceptable) {
    if ([".xlsx", ".csv"].includes(extension)) {
      if (extension === ".xlsx" && file.size / 1024 / 1024 > 10) {
        errors.push({
          msg: `File over 10 MB, please convert to CSV first`,
          code: "FILE_SIZE_LIMIT",
        });
        return { errors, isAcceptable: false, extension, file };
      }
      const parseFcn = extension === ".csv" ? parseCsv : xlsxParse;
      try {
        const value = await parseFcn(file, 100);
        const { columnTypes, parseErrors } = value;
        if (parseErrors.length > 0) {
          isAcceptable = false;
          errors = [...errors, ...parseErrors];
        }
        if (columnTypes.hasOwnProperty("id")) {
          isAcceptable = false;
          errors = [
            ...errors,
            {
              code: "INVALID_SIGNATURE",
              msg: '"id" is a reserved column name!',
            },
          ];
        }

        if (columnTypes.hasOwnProperty("")) {
          isAcceptable = false;
          errors = [
            ...errors,
            {
              code: "INVALID_SIGNATURE",
              msg: "The file has a column with empty column name!",
            },
          ];
        }
        if (requiredSignature) {
          const matches = doesSignatureMatch(requiredSignature, columnTypes);
          if (!matches) {
            isAcceptable = false;
            errors = [
              ...errors,
              { code: "INVALID_SIGNATURE", msg: "File signature is invalid!" },
            ];
          }
        }
        return { errors, isAcceptable, extension, ...value };
      } catch (e) {
        return { errors, isAcceptable: false, extension, file };
      }
    }
  }
  return { errors, isAcceptable: false, extension, file };
};

export const parseMD = (text, separator = "####") => {
  let textList = text.split(separator);
  textList.shift();
  return textList.map((s) => {
    return separator + s;
  });
};
