import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkResetExpiration, resetPassword } from '../../../lib/api-auth/api-auth';
import { logout } from '../../../redux/actions';

function getErrorMessage(err, password = '') {
	const errorMessages = {
		SHORT: `Please lengthen this text to 8 characters or more (you are currently using ${password.length} characters).`,
		LONG: `Please shorten this text to 50 characters or less (you are currently using ${password.length} characters).`,
		CONSECUTIVE: 'Passwords must not contain 4 consecutive same characters.',
		MATCH: 'Passwords must match.'
	};
	return errorMessages[err];
}

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
			isLoading: true,
			hasError: false,
			redirectSearch: null
		};
		this.firstPassword = React.createRef();
		this.secondPassword = React.createRef();
		this.submitButton = React.createRef();
	}

	componentDidMount() {
		const { location: { search } = undefined, logoutAction } = this.props;
		const id = search.split('?id=').pop();
		logoutAction();
		checkResetExpiration(id)
			.then(() => {
				this.setState({ status: 'display', isLoading: false });
			})
			.catch(() => this.setState({ status: 'redirect', redirectSearch: 'password_reset_expired=true' }));
	}

	handleFirstPasswordInput = () => {
		const { value: firstPasswordValue } = this.firstPassword.current;
		let validityText = '';
		// Check for 5 consecutive same characters
		if (/(.).*(\1{4,})/.test(firstPasswordValue)) {
			validityText = getErrorMessage('CONSECUTIVE');
		} else if (firstPasswordValue.length > 50) {
			validityText = getErrorMessage('LONG', firstPasswordValue);
		}
		this.firstPassword.current.setCustomValidity(validityText);
	};

	handleSecondPasswordInput = () => {
		const { value: secondPasswordValue } = this.secondPassword.current;
		const { value: firstPasswordValue } = this.firstPassword.current;
		let validityText = '';
		if (secondPasswordValue !== firstPasswordValue) {
			validityText = getErrorMessage('MATCH');
		}
		this.secondPassword.current.setCustomValidity(validityText);
	};

	handleSubmit = (event) => {
		if (event) {
			event.preventDefault();
		}
		const { value: password } = this.firstPassword.current;
		const { value: secondPasswordValue } = this.secondPassword.current;
		if (password && secondPasswordValue) {
			const { location: { search } = undefined } = this.props;
			const id = search.split('?id=').pop();
			this.setState({ isLoading: true }, () => {
				resetPassword(password, id)
					.then(() => {
						this.setState({
							status: 'redirect',
							redirectSearch: 'password_reset_success=true'
						});
					})
					.catch((e) => {
						const code = e.toString().split(': ')[1];
						const validityText = getErrorMessage(code, password);
						if (validityText) {
							this.firstPassword.current.setCustomValidity(validityText);
						}
						this.setState({ isLoading: false, hasError: true });
					});
			});
		}
	};

	renderChangePassword = () => {
		const { hasError, isLoading } = this.state;
		return (
			<div className="page-wrap">
				<form className="login-form" onSubmit={this.handleSubmit}>
					{hasError && (
						<div className="form-notification notification-error">
							<FontAwesomeIcon icon="exclamation-triangle" />
							<span>Something went wrong</span>
						</div>
					)}
					<input
						placeholder="New Password"
						className="login-textbox"
						type="password"
						onChange={this.handleFirstPasswordInput}
						ref={this.firstPassword}
						minLength="8"
						required
					/>
					<input
						placeholder="Re-enter password"
						className="login-textbox"
						type="password"
						onChange={this.handleSecondPasswordInput}
						ref={this.secondPassword}
						minLength="8"
						required
					/>
					<button className="submit-button secondary-button" disabled={isLoading} type="submit">
						Reset Password
					</button>
				</form>
			</div>
		);
	};

	render() {
		const { status, redirectSearch } = this.state;
		switch (status) {
			case 'redirect':
				return <Redirect to={`/login?${redirectSearch}`} />;
			case 'display':
				return this.renderChangePassword();
			default:
				return null;
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	logoutAction: (user) => dispatch(logout(user))
});

export default connect(null, mapDispatchToProps)(ResetPassword);
