import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { openSupport } from '../../../redux/actions';
import balloon from '../../../static/images/mem-baloons.svg'
import user from '../../../static/images/mem-user.svg'
import team from '../../../static/images/mem-team.svg'
import building from '../../../static/images/mem-building.svg'

const Membership2 = ({ openSupportAction }) => {

    const attributes = [
        {
            attribute: 'Autopilot Mode and Uncertainty Quantification',
            trial: 'checkmark',
            individual: 'checkmark',
            team: 'checkmark',
            business: 'checkmark'
        },
        {
            attribute: 'Total Number of users per account',
            trial: 1,
            individual: 1,
            team: 3,
            business: 10
        },
        {
            attribute: 'Total CPU hours per month',
            trial: 10,
            individual: 40,
            team: 180,
            business: 1000
        },
        {
            attribute: 'Total API Deployment',
            trial: 3,
            individual: 10,
            team: 10000,
            business: 'Unlimited'
        },
        {
            attribute: 'Total storage for raw data(GB)',
            trial: '',
            individual: 5,
            team: 30,
            business: 200
        },
        {
            attribute: 'Private Dataset (Based on GB storage)',
            trial: '',
            individual: 'checkmark',
            team: 'checkmark',
            business: 'checkmark'
        }
    ]
    return (
        <div className='membership2'>
            <div className='container'>
                <div className='memb-content'>
                    <div className='col-container no-border-radius'>
                        <div className='memb-row'>
                            <div className='col-1-title'>
                                Choose Your Plan
                            </div>
                        </div>
                        {
                            attributes.map(x => {
                                return (
                                    <div className='memb-attr'>{x.attribute}</div>
                                )
                            })
                        }
                    </div>
                    <div className='col-container'>
                        <div className='memb-row'>
                            <div className='col-title'>
                                <img src={balloon}></img>
                                <div className='col-2-title-text'>Trial</div>
                            </div>
                        </div>
                        {
                            attributes.map(x => {
                                if (x.trial === 'checkmark') {
                                    return (
                                        <div className='memb-row'>
                                            <div className="memb-check">
                                                <div className="memb-check__icon">
                                                    <FontAwesomeIcon icon="check" size="sm" />
                                                </div>
                                            </div>
                                        </div>)
                                }
                                return (
                                    <div className='memb-row'>{x.trial}</div>
                                )
                            })
                        }
                        <div className='memb-row' onClick={() => openSupportAction('Account Upgrade')} >
                            <div className='memb-contact'>CONTACT</div>
                        </div>
                    </div>
                    <div className='col-container'>
                        <div className='memb-row'>
                            <div className='col-title'>
                                <img src={user}></img>
                                <div className='col-2-title-text'>Individual</div>
                            </div>
                        </div>
                        {
                            attributes.map(x => {
                                if (x.individual === 'checkmark') {
                                    return (
                                        <div className='memb-row'>
                                            <div className="memb-check">
                                                <div className="memb-check__icon">
                                                    <FontAwesomeIcon icon="check" size="sm" />
                                                </div>
                                            </div>
                                        </div>)
                                }
                                return (
                                    <div className='memb-row'>{x.individual}</div>
                                )
                            })
                        }
                        <div className='memb-row' onClick={() => openSupportAction('Account Upgrade')} >
                            <div className='memb-contact'>CONTACT</div>
                        </div>
                    </div>
                    <div className='col-container'>
                        <div className='memb-row'>
                            <div className='col-title'>
                                <img src={team}></img>
                                <div className='col-2-title-text'>Team</div>
                            </div>
                        </div>
                        {
                            attributes.map(x => {
                                if (x.team === 'checkmark') {
                                    return (
                                        <div className='memb-row'>
                                            <div className="memb-check">
                                                <div className="memb-check__icon">
                                                    <FontAwesomeIcon icon="check" size="sm" />
                                                </div>
                                            </div>
                                        </div>)
                                }
                                return (
                                    <div className='memb-row'>{x.team}</div>
                                )
                            })
                        }
                        <div className='memb-row'>
                            <div className='memb-contact' onClick={() => openSupportAction('Account Upgrade')} >CONTACT</div>
                        </div>
                    </div>
                    <div className='col-container'>
                        <div className='memb-row'>
                            <div className='col-title'>
                                <img src={building}></img>
                                <div className='col-2-title-text'>Business</div>
                            </div>
                        </div>
                        {
                            attributes.map(x => {
                                if (x.business === 'checkmark') {
                                    return (
                                        <div className='memb-row'>
                                            <div className="memb-check">
                                                <div className="memb-check__icon">
                                                    <FontAwesomeIcon icon="check" size="sm" />
                                                </div>
                                            </div>
                                        </div>)
                                }
                                return (
                                    <div className='memb-row'>{x.business}</div>
                                )
                            })
                        }
                        <div className='memb-row'>
                            <div className='memb-contact memb-business' onClick={() => openSupportAction('Account Upgrade')} >CONTACT</div>
                        </div>
                    </div>


                </div>

            </div>


        </div>
    )
}



const mapDispatchToProps = (dispatch) => ({
    openSupportAction: (topic) => dispatch(openSupport(topic))
});


export default connect(null, mapDispatchToProps)(Membership2)