export const login = (user) => ({ type: 'LOGIN', user });
export const logout = () => ({ type: 'LOGOUT' });
export const enterProject = (project, dataset) => ({ type: 'ENTER_PROJECT', project, dataset });
export const enterDataset = (dataset) => ({ type: 'ENTER_DATASET', dataset });
export const enterCase = (cse) => ({ type: 'ENTER_CASE', case: cse });
export const waitPreprocessing = (preprocessing) => ({ type: 'WAIT_PREPROCESSING', preprocessing });
export const setGlobalMsg = (msgs) => ({ type: 'SET_GLOBAL_MSG', msgs });
export const closeSupport = () => ({ type: 'CLOSE' });
export const openSupport = (topic) => ({ type: 'OPEN', topic });
export const closePreprocessModal = () => ({ type: 'CLOSE_PREPROCESS' })
export const openPreprocessModal = (payload) => ({ type: 'OPEN_PREPROCESS', payload })
export const editSortOption = payload => ({ type: 'CHANGE', payload })
